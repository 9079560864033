
.w-100 {
    width: 100%;
}
.h-100 {
    height: 100%;
}
.p-30 {
    padding: 30px;
}

.p-top-30 {
    padding-top: 30px;
}

.t-align-center {
    text-align: center;
}

.m-top-10 {
    margin-top: 10px;
}

.m-top-15 {
    margin-top: 15px;
}