.home-image {
  background-image: linear-gradient(
    90deg,
    rgba(40, 99, 112, 0.3) 32%,
    #f4f1e8 32%
  ) !important;
  background-size: cover;
  padding-top: 20vh;
  background-color: #eaeaea !important;
}

.home-img-and-text-container {
  display: flex;
  height: 70%;
}

.img-container {
  flex-basis: 40%;
}

.text-container {
  flex-basis: 60%;
}

.lobby-img-container {
  width: 60%;
  height: auto;
  box-shadow: 0px 12px 18px -14px rgb(0 0 0 / 30%);
  animation: imageTransitionOut 1.5s ease-in-out;
}

.lobby-img {
  width: 100%;
}

.smiling-lady-img-container {
  width: 50%;
  height: auto;
  position: relative;
  top: -19%;
  left: 36%;
  box-shadow: 12px 12px 18px -14px rgb(0 0 0 / 30%);
  animation: imageTransitionIn 1.5s ease-in-out;
}

.similing-lady-img {
  width: 100%;
}

.text-container {
  font-family: "Martel Sans", Helvetica, Arial, Lucida, sans-serif;
  color: rgb(16, 68, 53) !important;
  margin-top: 7%;
}

.text-header1 {
  font-weight: 600;
  font-size: 20px;
  line-height: 1.2em;
  animation: textTransitonIn 1.5s ease-in-out;
}

.text-header2 {
  font-weight: 700;
  margin-top: 2%;
  font-size: 70px;
  line-height: 1.2em;
  animation: textTransitonIn 1s ease-in-out;
}

.button-container {
  display: flex;
  margin-top: 4%;
  animation: textTransitonIn 1s ease-in-out;
}

.book-button {
  width: 30%;
  height: 60px;
  border-radius: 50px;
  margin-right: 5%;
  font-size: 16px;
  font-family: "Martel Sans", Helvetica, Arial, Lucida, sans-serif !important;
  font-weight: 700 !important;
  text-transform: uppercase !important;
  color: white;
  background-color: #07c78c;
  border: 2px solid#07c78c;
  cursor: pointer;
  padding: 15px;
}

.book-button:hover {
  background-color: transparent;
  color: rgb(16, 68, 53) !important;
  border: 2px solid #07c78c;
  transition: all 0.4s ease-in-out;
}

.call-button {
  width: 35%;
  height: 60px;
  border-radius: 50px;
  font-size: 16px;
  font-family: "Martel Sans", Helvetica, Arial, Lucida, sans-serif !important;
  font-weight: 700 !important;
  color: rgb(16, 68, 53) !important;
  border: 2px solid #10443585;
  background-color: #f5f1e8;
  cursor: pointer;
  padding: 16px;
}

.call-button:hover {
  background-color: #10443585;
  color: white !important;
  border: 2px solid #10443585;
}

/* home section 1 start */
.home-section-1-container {
  display: flex;
  height: auto;
  font-family: "Martel Sans", Helvetica, Arial, Lucida, sans-serif;
  flex-direction: row;
  justify-content: space-around;
  flex-flow: wrap;
  padding: 5% 2% 5% 2%;
  animation: homeSectionOnIn 2s ease-in-out;
}

.home-section-1-item-1 {
  width: 50%;
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  top: 23%;
  align-self: flex-start;
  margin-top: 4%;
}

.home-section-1-item-2 {
  width: 30%;
}

.home-section-1-item-1 h1 {
  color: rgb(16, 68, 53) !important;
  font-weight: 700;
  font-size: 35px;
}

.home-section-1-item-1 p {
  color: rgb(16, 68, 53) !important;
  font-size: 22px;
  padding-bottom: 1em;
}

.learn-more-button {
  width: 30%;
  height: 60px;
  border-radius: 50px;
  font-size: 16px;
  font-family: "Martel Sans", Helvetica, Arial, Lucida, sans-serif !important;
  font-weight: 700 !important;
  text-transform: uppercase !important;
  color: white;
  background-color: #07c78c;
  border: 2px solid#07c78c;
  cursor: pointer;
}

.learn-more-button:hover {
  background-color: transparent !important;
  color: rgb(16, 68, 53) !important;
  border: 2px solid #07c78c;
  transition: all 0.4s ease-in-out;
}

.home-section-1-item-2 hr {
  background-color: rgba(112, 82, 35, 0.24);
}

.home-section-1-item-2 img {
  margin: 10% 0 5% 0;
}

.home-section-1-item-2 h1 {
  color: #8e5500 !important;
  font-size: 30px;
}

.home-section-1-item-2 p {
  color: #8e5500 !important;
  font-size: 16px;
  margin-bottom: 10%;
}
/* home section 1 end */

/* home section 2 start */
.home-section-2-container {
  display: flex;
  height: auto;
  font-family: "Martel Sans", Helvetica, Arial, Lucida, sans-serif;
  flex-direction: row;
  justify-content: space-around;
  flex-flow: wrap;
  margin-bottom: 10%;
}

.home-section-2-item-1 {
  width: 50%;
  background-color: #eff4f5;
}

.home-section-2-img-container {
  width: 60%;
  position: relative;
  top: 20%;
  left: 20%;
}

.home-section-2-img-container img {
  width: 100%;
}

.home-section-2-item-2 {
  width: 50%;
  background-color: #03616f;
}

.home-section-2-data-container {
  padding: 10%;
  color: white !important;
}

.home-section-2-data-container h1 {
  font-size: 40px;
}

.home-section-2-data-container p {
  font-size: 18px;
  padding: 2% 0 2% 0;
}

.home-section-2-data-container ul {
  font-size: 18px;
  padding: 2% 0 2% 0;
  margin-left: 5%;
}

.learn-more-button-dark {
  width: 40%;
  height: 60px;
  border-radius: 50px;
  font-size: 16px;
  font-family: "Martel Sans", Helvetica, Arial, Lucida, sans-serif !important;
  font-weight: 700 !important;
  text-transform: uppercase !important;
  color: white;
  background-color: #99c0c5;
  border: 2px solid#99c0c5;
  cursor: pointer;
  margin-top: 5%;
}

.learn-more-button-dark:hover {
  background-color: transparent;
  color: white !important;
  border: 2px solid white;
  transition: all 0.4s ease-in-out;
}

/* home section 2 end */

/* home section 3 start */

.home-section-3-container {
  display: flex;
  height: auto;
  font-family: "Martel Sans", Helvetica, Arial, Lucida, sans-serif;
  flex-direction: row;
  justify-content: space-around;
  flex-flow: wrap;
  margin-bottom: 5%;
}

.home-section-3-item-1 {
  width: 50%;
}

.home-section-3-item-2 {
  width: 50%;
}

.home-section-3-data-container {
  margin: 10%;
  color: #03616f;
}

.home-section-3-data-container h1 {
  font-size: 50px;
}

.home-section-3-data-container p {
  font-size: 22px;
  margin-bottom: 5%;
}

.home-section-3-img-container {
  width: 80%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.home-section-3-img-container img {
  width: 100%;
  transition: transform 300ms ease 0ms, box-shadow 300ms ease 0ms;
}

/* home section 3 end */

/* home section 4 start */
.home-section-4-container {
  display: flex;
  height: auto;
  font-family: "Martel Sans", Helvetica, Arial, Lucida, sans-serif;
  flex-direction: row;
  justify-content: space-around;
  flex-flow: wrap;
  margin-bottom: 5%;
}

.home-section-4-item-1 {
  width: 50%;
}

.home-section-4-item-2 {
  width: 50%;
}

.home-section-4-data-container {
  margin: 6% 10% 10% 10%;
  color: #03616f;
}

.home-section-4-data-container h1 {
  font-size: 50px;
}

.home-section-4-data-container p {
  font-size: 22px;
  margin-bottom: 5%;
}

.home-section-4-img-container {
  width: 80%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.home-section-4-img-container img {
  width: 100%;
}
/* home section 4 end */

/* home section 5 start */
.home-section-5-container {
  display: flex;
  height: auto;
  font-family: "Martel Sans", Helvetica, Arial, Lucida, sans-serif;
  flex-direction: row;
  justify-content: space-around;
  flex-flow: wrap;
  margin-bottom: 5%;
}

.home-section-5-item-1 {
  width: 50%;
}

.home-section-5-item-2 {
  width: 50%;
}

.home-section-5-data-container {
  margin: 10%;
  color: #03616f;
}

.home-section-5-data-container h1 {
  font-size: 50px;
}

.home-section-5-data-container p {
  font-size: 22px;
  margin-bottom: 5%;
}

.home-section-5-img-container {
  width: 80%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.home-section-5-img-container img {
  width: 100%;
}
/* home section 5 end */

/* home section 6 start */
.home-section-6-container {
  display: flex;
  height: auto;
  font-family: "Martel Sans", Helvetica, Arial, Lucida, sans-serif;
  flex-direction: row;
  justify-content: space-around;
  flex-flow: wrap;
  background: linear-gradient(90deg, #99c0c5 50%, #eef3f4 50%);
}

.home-section-6-item-1 {
  width: 50%;
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  top: 10%;
  align-self: flex-start;
  margin-bottom: 10%;
}

.home-section-6-item-1 h1 {
  margin-top: 15%;
  margin-left: 10%;
  font-weight: 700;
  font-size: 35px;
  color: rgb(5 101 113);
}

.home-section-6-item-1 p {
  margin-top: 3%;
  margin-left: 10%;
  font-size: 22px;
  margin-right: 5%;
  color: rgb(5 101 113);
}

.home-section-6-item-2 {
  width: 50%;
}

.home-section-6-img-container {
  width: 50%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10%;
}

.home-section-6-img-container img {
  width: 100%;
}

.home-section-6-item-2 h1 {
  color: rgb(5 101 113) !important;
  font-size: 35px;
}

.home-section-6-item-2 p {
  color: rgb(5 101 113) !important;
  font-size: 16px;
  margin-bottom: 5%;
}

/* home section 6 end */

/* home section 7 start */
.home-section-7-container {
  background-color: #f9f9f9 !important;

  border: 1px solid #f9f9f9;
}

.home-section-7-container hr {
  width: 90%;
  display: block;
  margin: 8% auto 0 auto;
  background-color: #99c0c5;
}

.home-section-7-data-container {
  width: 90%;
  background-color: white !important;
  display: flex;
  height: auto;
  font-family: "Martel Sans", Helvetica, Arial, Lucida, sans-serif;
  flex-direction: row;
  justify-content: space-around;
  flex-flow: wrap;
  margin: 3% auto 1% auto;
  padding-bottom: 3%;
}

.home-section-7-data-item-1 {
  width: 40%;
}

.home-section-7-data-item-1 h1 {
  margin-top: 15%;
  font-size: 30px;
  color: rgb(5 101 113) !important;
  margin-left: 10%;
}

.home-section-7-img-container-1 {
  width: 75%;
  margin-top: 8%;
  margin-left: 10%;
}

.home-section-7-img-container-1 img {
  width: 100%;
}

.home-section-7-data-item-2 {
  width: 60%;
}

.home-section-7-data {
  margin-top: 10%;
}

.home-section-7-data a {
  color: #dab968 !important;
  cursor: pointer;
}

.home-section-7-data p {
  font-size: 22px;
  margin-top: 3%;
  color: rgb(5 101 113) !important;
}

.home-section-7-img-container-2 {
  margin-top: 5%;
  width: 40%;
}

.home-section-7-img-container-2 img {
  width: 100%;
}

/* home section 7 end */

/* home section 8 start */
.home-section-8-container {
  background-image: radial-gradient(
    circle at left,
    #dab968 78%,
    #f4f1e8 78%
  ) !important;
  display: flex;
  height: auto;
  font-family: "Martel Sans", Helvetica, Arial, Lucida, sans-serif;
  flex-direction: row;
  justify-content: space-around;
  flex-flow: wrap;
}

.home-section-8-item-1 {
  width: 40%;
}

.home-section-8-item-1 h1 {
  margin: 25% 15% 0 15%;
  color: white !important;
  font-weight: 700;
  font-size: 60px;
  color: #ffffff !important;
  line-height: 1.2em;
}

.home-section-8-item-2 {
  width: 60%;
}

.home-section-8-img-container {
  margin: 15% 0 4% 0;
  width: 8%;
}

.home-section-8-img-container img {
  width: 100%;
}

.home-section-8-item-2 p {
  width: 80%;
  font-size: 22px;
  margin-bottom: 5%;
  color: #8e5500 !important;
}

.home-section-8-item-2 button {
  margin-bottom: 10%;
}

/* home section 8 end */

/* home section 9 start */

.home-section-9-container {
  display: flex;
  height: auto;
  font-family: "Martel Sans", Helvetica, Arial, Lucida, sans-serif;
  flex-direction: row;
  justify-content: space-around;
  flex-flow: wrap;
}

.home-section-9-item-1 {
  width: 35%;
  position: relative;
}

.home-section-9-item-2 {
  width: 35%;
}

.home-section-9-item-3 {
  width: 30%;
}

.home-section-9-img-container {
  margin: 10% 0 10% 10%;
}

.home-section-9-img-container-row {
  display: flex;
  flex-wrap: wrap;
  padding: 0 4px;
}

.home-section-9-img-container-columm {
  flex: 48%;
  max-width: 48%;
  padding: 0 4px;
}

.home-section-9-img-container-columm img {
  margin-top: 8px;
  vertical-align: middle;
  width: 100%;
}

.home-section-9-item-2 h1 {
  font-size: 40px;
  font-weight: 700;
  color: rgb(5 101 113) !important;
  margin: 22% 5% 0 20%;
}

.home-section-9-item-2 i {
  font-size: 40px;
  vertical-align: -15px;
  margin-right: 2%;
}

.home-section-9-item-2 div {
  font-size: 22px;
  color: rgb(5 101 113) !important;
  margin: 5% 5% 5% 20%;
}

.home-section-9-selection {
  cursor: pointer;
}

.home-section-9-selection:hover {
  margin-left: 23%;
  transition: 0.5s ease-in-out;
}

.iframe {
  width: 100%;
  height: 100%;
  border: none;
}

/* home section 9 end */

/* home section 10 start */
.home-section-10-container {
  background-color: rgba(234, 234, 234, 0.62) !important;
  padding: 5vh;
  box-shadow: 0px 12px 18px -6px rgb(0 0 0 / 18%);
}

.home-section-10-img-main-container-1 {
  display: flex;
  height: auto;
  flex-direction: row;
  justify-content: space-around;
  flex-flow: wrap;
  align-items: center;
}

.home-section-10-img-main-container-2 {
  display: flex;
  height: auto;
  flex-direction: row;
  justify-content: space-around;
  flex-flow: wrap;
  align-items: center;
  margin-top: 4%;
}

.home-section-10-img-container {
  width: 10%;
}

.home-section-10-img-container img {
  width: 100%;
}

/* home section 10 end */

@media screen and (max-width: 1024px) {
  .button-container {
    margin-bottom: 5%;
  }

  .book-button {
    width: 35%;
    padding: 15px;
  }

  .call-button {
    width: 37%;
    padding: 16px;
  }

  .learn-more-button {
    width: 45%;
  }

  .home-section-1-item-1 {
    top: 10%;
  }

  .home-section-3-data-container h1 {
    font-size: 30px;
  }

  .home-section-3-data-container p {
    font-size: 16px;
    margin-bottom: 5%;
  }

  .home-section-3-img-container {
    margin-top: 8%;
  }

  .home-section-4-data-container h1 {
    font-size: 30px;
  }

  .home-section-4-data-container p {
    font-size: 16px;
    margin-bottom: 5%;
  }

  .home-section-4-img-container {
    margin-top: 3%;
  }

  .home-section-5-data-container h1 {
    font-size: 30px;
  }

  .home-section-5-data-container p {
    font-size: 16px;
    margin-bottom: 5%;
  }

  .home-section-5-img-container {
    margin-top: 8%;
  }

  .home-section-9-img-container-columm {
    flex: 45%;
    max-width: 45%;
    padding: 0 4px;
  }

  .home-section-9-item-2 h1 {
    font-size: 30px;
  }

  .home-section-9-item-2 i {
    font-size: 30px;
    vertical-align: -10px;
  }

  .home-section-9-item-2 div {
    font-size: 16px;
  }
}

@media screen and (max-width: 980px) {
  .home-image {
    background-image: url("../images/smiling-woman-4.jpeg") !important;
    width: 100%;
    background-size: cover;
    background-blend-mode: multiply;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #c6c6c6 !important;
  }

  .img-container {
    display: none;
  }

  .text-container {
    flex-basis: 90%;
    margin-top: 0;
    margin-left: 5%;
    color: white !important;
  }
  .text-header1 {
    font-size: 20px;
  }

  .text-header2 {
    font-size: 60px;
  }

  .book-button {
    width: 30%;
    height: 60px;
    border-radius: 50px;
    margin-right: 5%;
    font-size: 16px;
    font-family: "Martel Sans", Helvetica, Arial, Lucida, sans-serif !important;
    font-weight: 700 !important;
    text-transform: uppercase !important;
    color: white;
    background-color: #07c78c;
    border: 2px solid#07c78c;
    cursor: pointer;
    padding: 15px;
  }

  .book-button:hover {
    background-color: transparent;
    color: white !important;
    border: 2px solid #07c78c;
    transition: all 0.4s ease-in-out;
  }

  .call-button {
    width: 35%;
    height: 60px;
    border-radius: 50px;
    font-size: 16px;
    font-family: "Martel Sans", Helvetica, Arial, Lucida, sans-serif !important;
    font-weight: 700 !important;
    color: white !important;
    border: 2px solid #07c78c;
    background-color: transparent;
    cursor: pointer;
    padding: 16px;
  }

  .call-button:hover {
    background-color: #10443585;
    color: white !important;
    border: 2px solid #10443585;
  }

  .home-section-1-item-1 {
    width: 90%;
    margin: 5% 0;
    position: static;
  }

  .home-section-1-item-2 {
    width: 90%;
    margin: 5% 0;
  }

  .home-section-2-item-1 {
    width: 100%;
  }

  .home-section-2-img-container {
    width: 60%;
    left: 0;
    top: 0;
    display: block;
    margin: 10% auto 5% auto;
  }

  .home-section-2-item-2 {
    width: 100%;
  }

  .home-section-3-item-1 {
    width: 90%;
  }

  .home-section-3-item-2 {
    width: 100%;
  }

  .home-section-3-data-container {
    margin: 5%;
  }

  .home-section-3-container {
    flex-direction: row-reverse !important;
    flex-flow: wrap-reverse !important;
  }

  .home-section-4-item-1 {
    width: 100%;
  }

  .home-section-4-item-2 {
    width: 90%;
  }

  .home-section-4-data-container {
    margin: 5%;
  }

  .learn-more-button {
    width: 35%;
  }

  .home-section-5-item-1 {
    width: 90%;
  }

  .home-section-5-item-2 {
    width: 100%;
  }

  .home-section-5-data-container {
    margin: 5%;
  }

  .home-section-5-container {
    flex-direction: row-reverse !important;
    flex-flow: wrap-reverse !important;
  }

  .home-section-6-container {
    background: none;
    margin-bottom: 0;
  }

  .home-section-6-item-1 {
    width: 100%;
    position: static;
    background-color: #99c0c5;
    margin-bottom: 0;
  }

  .home-section-6-item-1 h1 {
    color: white !important;
  }

  .home-section-6-item-1 p {
    color: white !important;
  }

  .home-section-6-item-2 {
    width: 100%;
  }

  .home-section-6-item-2 {
    background-color: #99c0c5;
  }

  .home-section-6-item-2 h1 {
    color: white !important;
  }

  .home-section-7-data-item-1 {
    width: 100%;
  }

  .home-section-7-data-item-2 {
    width: 100%;
  }

  .home-section-7-data {
    margin-left: 10%;
  }

  .home-section-7-data p {
    font-size: 16px;
  }

  .home-section-7-img-container-2 {
    margin-left: 10%;
  }

  .home-section-8-item-1 {
    width: 100%;
  }

  .home-section-8-item-2 {
    width: 100%;
  }

  .home-section-8-item-1 h1 {
    margin: 10% 10% 0 8%;
  }

  .home-section-8-img-container {
    margin: 5% 0 4% 8%;
    width: 8%;
  }

  .home-section-8-item-2 p {
    margin: 0 0 5% 9%;
  }

  .home-section-8-item-2 button {
    margin: 0 0 5% 8%;
  }

  .home-section-9-item-1 {
    width: 100%;
  }

  .home-section-9-item-2 {
    width: 100%;
  }

  .home-section-9-item-3 {
    display: none;
  }

  .home-section-9-img-container {
    margin: 10% 5% 0 10%;
  }

  .home-section-9-item-2 h1 {
    margin: 5% 0 5% 10%;
  }

  .home-section-9-item-2 div {
    margin: 0 0 5% 15%;
  }

  .home-section-10-container {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .text-header2 {
    font-size: 45px;
  }

  .button-container {
    display: block;
  }

  .book-button {
    width: 30%;
    padding: 15px;
  }

  .call-button {
    width: 40%;
    padding: 16px;
  }
}

@media screen and (max-width: 550px) {
  .text-header2 {
    font-size: 40px;
  }

  .book-button {
    width: 40%;
    padding: 15px;
  }

  .call-button {
    margin-top: 4%;
    width: 60%;
    margin-bottom: 20%;
    padding: 16px;
  }

  .learn-more-button {
    width: 60%;
  }

  .learn-more-button-dark {
    width: 60%;
  }
}

@media screen and (max-width: 414px) {
  .book-button {
    width: 50%;
    padding: 15px;
  }

  .call-button {
    margin-top: 4%;
    width: 70%;
    margin-bottom: 20%;
    padding: 16px;
  }
}

@media screen and (max-width: 355px) {
  .book-button {
    width: 60%;
    padding: 15px;
  }

  .call-button {
    margin-top: 4%;
    width: 80%;
    margin-bottom: 20%;
    padding: 16px;
  }
}

@keyframes textTransitonIn {
  from {
    opacity: 0;
    transform: translateY(20%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes imageTransitionIn {
  from {
    opacity: 0;
    transform: translateX(20%);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes imageTransitionOut {
  from {
    opacity: 0;
    transform: translateX(-20%);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes homeSectionOnIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.link-2 {
  text-decoration: none;
  color: #104435;
}

.link-2:hover {
  color: #104435;
}