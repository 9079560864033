.ot-container- {
  background-color: #fff;
}

.ot-container > div {
  padding: 30px 0 30px 0;
  color: #104435;
}

.ot-doctor-img {
  border-radius: 100%;
  object-fit: cover;
  height: 250px;
}
