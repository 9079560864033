.ih-img-container {
  position: relative;
}

.ih-intro-img {
  height: 50vh;
  width: 100%;
  object-fit: cover;
}

.ih-header {
  position: absolute;
  bottom: 0;
  background-color: #07c78d63;
  padding: 30px 60px 30px 60px;
  display: flex;
  align-items: center;
  color: #fff;
  width: 100%;
}

.ih-sub-header {
  background-color: #07c78c;
  padding: 10px 60px 10px 60px;
  display: flex;
  align-items: center;
  color: #fff;
  width: 100%;
}
