.iaf-container- {
  background-color: #fff;
}

.iaf-container > div {
  padding: 30px 0 30px 0;
  color: #104435;
}
.insurance-container {
  padding: 5px;
}

.insurance {
  height: 100%;
  width: 100%;
  padding: 10px;
  background: #d4eed4;
}