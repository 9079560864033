.faq-container {
  background-color: rgb(234, 234, 234);
  border: 1px solid rgb(201, 201, 201);
  width: 80%;
  padding: 0px 10px 0px 10px;
  height: 65px;
  overflow-y: hidden;
  transition: height 300ms linear;
  border-radius: 8px;
  color: #104435;
}

.faq-header {
  height: 65px;
  display: flex;
  align-items: center;
  width: 100%;
}

.faq-header-title {
  font-size: 1.1rem;
  font-weight: bold;
  display: flex;
  justify-content: flex-start;
  width: 90%;
}

.faq-header-btn {
  display: flex;
  justify-content: flex-end;
  width: 10%;
  font-size: 1.2rem;
  margin-right: 3px;
  cursor: pointer;
}

.faq-body {
  padding: 5px 0 10px 0;
}
