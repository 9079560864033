.upper-header {
  font-family: "Martel Sans", Helvetica, Arial, Lucida, sans-serif;
  text-align: center;
  background-color: #e3e1dc;
  color: rgb(16, 68, 53);
  padding: 10px;
  font-weight: 500;
  line-height: 23px;
}

.upper-header-anchor {
  text-decoration: none;
  color: rgb(16, 68, 53);
}

.sticky-nav {
  position: fixed;
  width: 100%;
  z-index: 1;
  animation: navBarTranstition 0.5s ease-in-out;
}

.nav-container {
  align-items: center;
  display: flex;
  list-style: none;
  width: auto;
  background-color: #f9f9f9 !important;
}

.logo-container {
  align-items: center;
  display: flex;
  margin-left: 20px;
  padding: 20px;
}

.logo {
  width: 60px;
}

.logo-name {
  text-transform: uppercase;
  text-decoration: none;
  color: green;
  font-size: 40px;
}

#check {
  display: none;
}

.check-button {
  display: none;
}

.menu-container {
  margin-left: auto;
  margin-right: 0;
}

.nav-items-container {
  display: flex;
  list-style: none;
  margin-top: 7%;
  align-content: flex-end;
}

.nav-items-container li {
  margin: 0 14px;
  cursor: pointer;
  font-family: Helvetica, Arial, Lucida, sans-serif;
  color: rgb(16, 68, 53);
  font-size: 16px;
}

.nav-main-items {
  font-weight: 700;
}

.nav-main-items:hover {
  opacity: 0.7;
}

.nav-arrowdown-img {
  font-size: 20px;
  margin-left: 4px;
  position: relative;
  top: 8px;
}

.nav-button {
  width: 139.05px;
  height: 48px;
  border-radius: 50px;
  background-color: #07c78c;
  border: 2px solid#07c78c;
  color: white !important;
  font-size: 17px;
  cursor: pointer;
  position: relative;
  top: -8px;
  transition: all 0.4s ease-in-out;
}

.nav-button:hover {
  background-color: white;
  color: rgb(16, 68, 53) !important;
  border: 2px solid #07c78c;
}

.dental-services-list {
  background-color: white !important;
  border-top: 4px solid rgb(16, 68, 53);
  display: none;
  flex-direction: column;
  list-style: none;
  padding: 20px;
  position: absolute;
  top: 100%;
  width: 250px;
  z-index: 1;
  overflow: none;
}

.dental-services-container li {
  margin: 5px 5px;
  white-space: nowrap;
  padding: 5px 5px 5px 10px;
  width: 180px;
}

.dental-services-container {
  position: relative;
}

.general-container {
  position: relative;
}

.dental-services-container:hover .dental-services-list {
  display: block;
}

.dental-services-container li:hover {
  background-color: rgb(225, 225, 225);
  opacity: 0.7;
}

.header-book-button-container {
  display: flex;
  animation: textTransitonIn 1s ease-in-out;
}

.header-book-button {
  margin-right: 10px;
  width: 180px;
  border-radius: 50px;
  font-size: 16px;
  font-family: "Martel Sans", Helvetica, Arial, Lucida, sans-serif !important;
  font-weight: 700 !important;
  text-transform: uppercase !important;
  color: white;
  background-color: #07c78c;
  border: 2px solid#07c78c;
  cursor: pointer;
  padding: 4px;
}

.header-book-button:hover {
  background-color: transparent;
  color: rgb(16, 68, 53) !important;
  border: 2px solid #07c78c;
  transition: all 0.4s ease-in-out;
}

.header-book-button-color {
  color: white;
}

@media screen and (max-width: 1024px) {
  .container {
    margin: 0;
  }

  .logo-container {
    width: 100%;
    margin-left: 30%;
  }

  .logo {
    width: 40px;
  }

  .logo-name {
    text-transform: uppercase;
    text-decoration: none;
    color: black;
    font-size: 20px;
  }

  .nav-button {
    transition: none;
    margin-top: 3%;
    width: 110%;
    margin-bottom: 3%;
  }

  .check-button {
    display: flex;
    float: right;
    justify-content: flex-end;
    width: 100%;
    font-size: 30px;
    color: #04c78a;
    cursor: pointer;
    font-weight: 700;
    margin-right: 10%;
  }

  .nav-arrowdown-img {
    display: none;
  }

  .nav-items-container {
    display: flex;
    align-items: flex-start;
    background-color: #fff;
    flex-direction: column;
    padding-left: 1px;
    margin-top: 4%;
    left: 8%;
    margin-left: auto;
    margin-right: auto;
    z-index: 1;
    box-shadow: 0 2px 5px rgb(0 0 0 / 10%);
  }

  .nav-items-container-bak {
    display: flex;
    align-items: flex-start;
    width: 80%;
    background-color: #fff;
    flex-direction: column;
    position: absolute;
    visibility: hidden;
    padding: 10px;
    margin-top: 4%;
    left: 8%;
    margin-left: auto;
    margin-right: auto;
    z-index: 1;
    box-shadow: 0 2px 5px rgb(0 0 0 / 10%);
  }

  .nav-items-container-animation {
    animation: dropdownBarOut 1s ease-in-out;
  }

  .nav-items-container li {
    color: rgb(16, 68, 53);
    padding: 10px 5%;
    display: block;
    width: 80%;
  }

  .dental-services-container {
    pointer-events: none;
  }

  .dental-services-list {
    border-top: none;
    flex-direction: column;
    list-style: none;
    display: flex;
    padding: 20px;
    top: 0;
    position: static;
    overflow: none;
    width: 100%;
  }

  .nav-items-container li ul li {
    border-bottom: 3px solid rgba(0, 0, 0, 0.03);
  }
}

@media screen and (max-width: 980px) {
  .nav-items-container {
    margin-top: 5%;
  }
}

@media screen and (max-width: 425px) {
  .logo-container {
    width: 100%;
    margin-left: 10%;
  }

  .check-button {
    margin-right: 8%;
  }

  .nav-items-container {
    margin-top: 9%;
  }
}

@media screen and (max-width: 375px) {
  .nav-items-container {
    margin-top: 11%;
  }
}

@media screen and (max-width: 320px) {
  .nav-items-container {
    margin-top: 12%;
  }
}

@keyframes navBarTranstition {
  from {
    opacity: 0.5;
    transform: translateY(-2%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes dropdownBarOut {
  0% {
    opacity: 0;
    transform: translateY(-5%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
