.footer-container {
  background-color: #104435;
}

.footer-container > div {
  padding: 30px 0 30px 0;
  color: #fff;
}

.footer-strip-container {
  background-color: #0d3026;
}

.footer-quick-links li:hover {
  cursor: pointer;
  text-decoration: underline;
}

.footer-strip-container > div {
  padding: 10px 0 10px 0;
  color: #fff;
}

.footer-strip-icon {
  font-size: 2rem;
}

.footer-book-button-container {
  display: flex;
  margin-top: 4%;
  animation: textTransitonIn 1s ease-in-out;
}

.footer-book-button {
  width: 154px;
  height: 60px;
  border-radius: 50px;
  margin-right: 5%;
  font-size: 16px;
  font-family: "Martel Sans", Helvetica, Arial, Lucida, sans-serif !important;
  font-weight: 700 !important;
  text-transform: uppercase !important;
  color: white;
  background-color: #07c78c;
  border: 2px solid#07c78c;
  cursor: pointer;
  padding: 15px;
}

.footer-book-button:hover {
  background-color: transparent;
  color: rgb(222, 236, 14) !important;
  border: 2px solid #07c78c;
  transition: all 0.4s ease-in-out;
}

.ct-text-block {
  margin-left: 5px;
  margin-right: 5px;
  color: #596368;
  line-height: 1.28;
  font-size: 16px;
}

.ct-link-text {
  color: #ffff;
  font-size: 16px;
  font-weight: 400;
  font-family: 'poppins-regular-400';
  display: inline-block;
}
