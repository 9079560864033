.specials-book-button {
  border-radius: 50px;
  margin-right: 5%;
  font-size: 16px;
  font-family: "Martel Sans", Helvetica, Arial, Lucida, sans-serif !important;
  font-weight: 700 !important;
  text-transform: uppercase !important;
  color: white;
  background-color: #07c78c;
  border: 2px solid#07c78c;
  cursor: pointer;
  text-align: center;
}

.special-card-zig {
   background-color: aliceblue;
}

.special-card-zag {
  background-color:beige;
}