.yfv-container {
  background-color: #fff;
}

.yfv-container > div {
  padding: 30px 0 30px 0;
  color: #104435;
}

.yfv-link {
  color: #04b27e;
  cursor: pointer;
  text-decoration: none;
}

.yfv-link:hover {
  color: #04b27e;
  text-decoration: underline;
}

.yfv-img {
  border-radius: 8px;
}
